.StaggingFooterContainer {
  width: 100%;
  z-index: 3;
  position: fixed;
  bottom: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff3b;
  font-size: 24px;
}

@media screen and (max-width: 991px) {
  .StaggingHeaderContainer {
    height: 70%;
    margin: 0 5px;
    font-size: 20px;
  }
}

@media screen and (max-width: 400px) {
  .StaggingHeaderContainer {
    font-size: 15px;
  }
}
