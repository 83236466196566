.FormRow {
  /* margin-bottom: 2rem !important; */
  /* align-items: center; */
}

.FormRow.flexEnd {
  justify-content: flex-end;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}
