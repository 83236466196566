.File {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 10px 0;
  width: 100%;
  height: 100px;

  cursor: pointer;
}

.hasErrors {
  border: 2px solid red;
}

.Container > label {
  color: black;
  line-height: 19px;
  font-size: 16px;
  height: 19px;
  margin-bottom: 4px;
}

.Container > .links {
  display: flex;
  flex-direction: row;
}

.Container .removeLink {
  margin-left: 5px;
  cursor: pointer;
}

.Container .segment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
  padding: 1em 0.7em;
}

.Container .segment.small {
  padding: 0.3em;
}

.smallPreviewIcon {
  display: none !important;
}

.uploadBtn {
  margin-right: 10px;
  padding: 6px 10px !important;
}

.smallUploadBtn {
  margin-right: 0px;
  padding: 6px 2px 6px 6px !important;
}

.uploadIcon {
  margin-left: 6px !important;
}
