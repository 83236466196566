.TableScroll {
  display: block;
  white-space: nowrap;
  max-height: 300px;
  overflow: auto;
}

.ErrorsContainer {
  max-height: 300px;
  overflow: auto;
}
