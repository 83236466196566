.tableContainer {
  /* Set a maximum height for the table container, adjust as needed */
  max-height: 450px;
  overflow: auto;
  width: 100%;
}

.tableHeaderRow {
  position: sticky;
  top: 0;
  z-index: 1;
}

.dateCell {
  white-space: nowrap;
}
