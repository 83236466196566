.SubmitButtonRow {
  display: flex;
  justify-content: flex-end;
}
.adminCommentsLabel {
  display: flex;
  align-items: center;
}
.adminCommentsLabel > h4 {
  margin: 0;
  padding-right: 20px;
}
