.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container > label {
  color: black;
  line-height: 19px;
  font-size: 16px;
  height: 19px;
  margin-bottom: 4px;
}

.container .select {
  font-size: 16px;
  box-sizing: border-box;
  background-color: white !important;
}

.select > div:first-of-type {
  min-height: 56px;
  border-color: rgba(34, 36, 38, 0.15);
}
