.currencyRow {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1em;

  margin-bottom: 1em;
}

.actionsLabel {
  font-weight: bold;
  margin-right: 10px;
}
