.SortableItem {
  /* cursor: move;
  border: 1px dashed black;
  padding: 1rem;
  margin-bottom: 0.5rem; */
  user-select: none;
}

.NonSortableItem {
  border: 1px solid black;
  padding: 1rem;
  margin-bottom: 0.5rem;
  user-select: none;
}
