.tableRow {
  display: grid;
  grid-template-columns: 0.4fr 180px 2fr 2.5fr repeat(10, 1fr) 50px;

  @media screen and (max-width: 1690px) {
    grid-template-columns: 40px 180px 2fr 2fr repeat(10, 0.8fr) 40px;
  }
}

.tableCell {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  padding: 0.5em 0.7em;
  box-sizing: border-box;
  border-left: 1px solid rgba(34, 36, 38, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;
}

.tableCell:first-of-type {
  border-left: none;
}
