.header {
  display: flex;
  justify-content: space-between;
}

.label {
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
}

.leadsWrapper {
  padding-bottom: 10px;
}
