.LoginForm {
  border-radius: 4px;

  width: 430px;
}

.LoginForm a {
  text-decoration: underline;
}

.LoginForm > .formContainer {
  margin: 50px 20px;
}

.LoginForm > .formContainer > .submitButton {
  width: 100%;

  font-weight: bold;
  font-size: 17px;
}

.LoginForm > .formContainer > .forgotPassword {
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
  color: #4183c4;
  text-decoration: underline;
}

.LoginForm > .footer {
  background-color: #e1e3e8;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
}
