.container {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 0.5fr 4fr;
}

.container label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  margin-bottom: 4px !important;
}

.container select {
  min-height: 250px;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  overflow-x: auto;
}

.container .input {
  margin-bottom: 10px !important;
  height: 48px;
  padding: 13px 6px !important;
  box-sizing: border-box;
}

.container .btnsColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container .btnsColumn button {
  width: 50px;
  margin-bottom: 10px;
}

.container .selectedOptions {
  align-self: flex-end;
}

.selectedOptions .header {
  padding-bottom: 10px;
}
