.LoginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: white;
}

.LoginWrapper > .title {
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  padding-bottom: 20px;
  color: #89b83e;
}

.LoginWrapper > .subtitle {
  font-size: 20px;
  text-align: center;
  color: #f5680b;
}
.LoginWrapper > .subtitle > a {
  color: inherit;
  text-decoration: underline;
}

.LoginWrapper .logo {
  height: 36px;
}
