.calendarWrapper {
  margin-bottom: 2rem;
}
.elementsContainer {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.elementsContainer > * {
  /* Exclude 2rem gap */
  flex: 1 1 calc(50% - 2rem);
}
