.Split {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 2rem;
}

.pageContent {
  max-width: 1700px !important;
}
