@import 'assets/media_variables.scss';

.NavigationWrapper {
  width: 100%;
  background-color: white;
  box-shadow: 0 10px 11px 0 rgba(0, 0, 0, 0.09);
  display: flex;
  justify-content: center;
  z-index: 3;
  position: fixed;

  .container {
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;

    .leftNavItems {
      display: flex;
      align-items: center;
      .logo {
        height: 36px;
      }
    }
  }
  .container::-webkit-scrollbar {
    display: none;
  }
}

.Menu {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

  border: none !important;
  border-radius: 0 !important;

  margin: 0 !important;
}
.dropdownItem :hover {
  background: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.dropdownItem .link {
  padding: 10px;
  width: 100%;
  display: block;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}
