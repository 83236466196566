.container {
  display: flex;
  padding-bottom: 28px;
}

.item {
  margin-right: 40px;
}

.item > label {
  font-weight: 600;
}

.item > .clicky {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
