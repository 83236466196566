.File {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 10px 0;
  width: 100%;
  height: 100px;

  cursor: pointer;
}

.Container > label {
  color: black;
  line-height: 19px;
  font-size: 16px;
  height: 19px;
  margin-bottom: 4px;
}

.Container > .previewLink {
  padding-left: 70px;
}

.Container .segment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
