.dropdownItem :hover {
  background: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.dropdownItem .link {
  padding: 10px;
  width: 100%;
  display: block;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}
