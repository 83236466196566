.table {
  width: 100%;
  background: #fff;
  margin: 1em 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  border-radius: 0.28571429rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: separate;
  font-size: 1em;
  border-spacing: 2px !important;
}

.tableRow {
  display: grid;
  grid-template-columns: 0.4fr 180px 2fr 2.5fr repeat(10, 1fr) 50px;

  @media screen and (max-width: 1690px) {
    grid-template-columns: 40px 180px 2fr 2fr repeat(10, 0.8fr) 40px;
  }
}

.tableRow.header {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}

.tableCell {
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  padding: 0.5em 0.7em;
  box-sizing: border-box;
  border-left: 1px solid rgba(34, 36, 38, 0.1);

  display: flex;
  align-items: center;
}

.tableCell:first-of-type {
  border-left: none;
}

.tableCell.header {
  background: #f9fafb;

  @media screen and (max-width: 1690px) {
    padding: 0.5em 0.5em;
    word-break: break-all;
  }
}
