.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container > label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  margin-bottom: 4px;
}

.container textarea {
  border-radius: 0 !important;
  height: 48px;
  padding: 13px 7px !important;
  box-sizing: border-box;
  border: 1px solid black;
  font-size: 16px;
  line-height: 19px;
}

.hasErrors {
  outline-offset: -2px;
  border: 2px solid red !important;
}
