.Page {
  width: 100%;
  height: 100vh;
}

.PageBody {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  background-color: #f2f4f7;
}

.PageContent {
  padding: 80px 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  min-height: 100vh;
}

.maxPageContent {
  max-width: 1700px;
}

.PageWrapper {
  display: flex;
}
