.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container label {
  font-size: 16px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.withSelectAll {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.container .select {
  font-size: 15px;
  box-sizing: border-box;
  background-color: white !important;
}

.select > div:first-of-type {
  min-height: 48px;
  border-color: rgba(34, 36, 38, 0.15);
}

.hasErrors > div:first-of-type {
  outline-offset: -2px;
  border-color: red !important;
  border: 2px solid red !important;
}
