.Row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  margin-bottom: 15px;
}

.alignRight {
  grid-column-start: -1;
}
