@import 'assets/media_variables.scss';

.userName {
  display: inline;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.87);
  border-radius: 50%;
  padding: 6px;
  font-weight: bold;
}

.dropdownMenu {
  min-width: 100px;
  padding: 15px;

  box-sizing: border-box;
}

.dropdownMenu > a {
  display: inline-block;
  width: 100%;

  cursor: pointer;

  text-align: right;
}
