.SmallerSelect > div:first-of-type {
  min-height: 36px !important;
}

.Grid {
  display: grid;
  grid-gap: 0.5rem;
}

.Button {
  max-height: 36px;
}
