.item {
  padding-right: 35px;
  padding-bottom: 20px;
}

.item > label {
  font-weight: bold;
  line-height: 19px;
  font-size: 16px;
}

.item > .clicky {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}
