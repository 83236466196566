.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container > label {
  color: rgba(0, 0, 0, 0.87);
  line-height: 20px;
  font-size: 16px;
  height: 20px;
  margin-bottom: 5px;
}

.container .field {
  text-align: center;
  border-radius: 1 !important;
  height: 48px;
  padding: 16px !important;
  box-sizing: border-box;
  border: 2px solid black;
  font-size: 17px;
  line-height: 20px;
}

.container .hasErrors {
  border: 1px solid red !important;
}

.row {
  display: flex;
}

.row > * {
  width: 50% !important;
}

.dayPickerField {
  border-right: 1px solid #dedede !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.timeField {
  height: 48px;

  border-left: 0px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@media only screen and (max-width: 600px) {
  .row {
    flex-direction: column;
  }

  .row > * {
    width: 100% !important;
  }

  .row .dayPickerField {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    margin-bottom: 1rem !important;
  }

  .row .timeField {
    border-left: 1px solid #dedede !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }
}

.todayBtn {
  color: #2185d0;
  cursor: pointer;
  font-size: 13px;

  margin-left: auto;
}
