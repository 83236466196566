.article-status {
  padding: 3px 8px;
  color: white;
  border-radius: 10px;
  white-space: nowrap;
}

span.work-in-progress {
  background-color: red;
}

span.ready-for-editing {
  background-color: orange;
}

span.editing-in-progress {
  background-color: yellowgreen;
}

span.ready-for-publication {
  background-color: green;
}

span.live {
  background-color: blue;
}
