.contentWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-content: center;
  gap: 30px;
  max-width: 1300px;
}

.valuesWrapper {
  height: fit-content;
  margin-top: 0 !important;
}
.valuesContent > * > :first-child {
  margin-bottom: 1rem !important;
}

@media screen and (max-width: 600px) {
  .contentWrapper {
    grid-template-columns: 1fr;
  }
}
