.wrapper {
  max-height: 40vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
.buttonContainer > h4 {
  margin: 0;
}
.content {
  white-space: pre-wrap;
  overflow: auto;
  margin: 15px 5px;
}
