.File {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  margin: 10px 0;
  width: 100%;
  height: 100px;

  cursor: pointer;
}
