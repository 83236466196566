.Grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
}

.Grid table {
  margin: 0 !important;
}

.btnsRow {
  display: flex;
  flex-direction: row;
  gap: 1px;
  padding-bottom: 20px;
}

.statusTitle {
  padding-right: 5px;
}

.statusLabel {
  font-weight: bold;
  padding-right: 15px;
}

.link {
  color: white;
  text-decoration: underline;
}
