.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container > label {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  margin-bottom: 4px;
}

.container.small input {
  padding: 11px 4px !important;
}

.container input {
  border-radius: 0 !important;
  height: 48px;
  padding: 13px 7px !important;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 19px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  width: 100%;
}

.hasErrors {
  outline-offset: -2px;
  outline: 2px solid red !important;
}
