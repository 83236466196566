.Flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Flex.spaceBetween {
  justify-content: space-between;
}

.Flex.flexStart {
  justify-content: flex-start;
}

.Flex.flexEnd {
  justify-content: flex-end;
}

.Flex.alignStart {
  align-items: flex-start;
}

.Flex.alignEnd {
  align-items: flex-end;
}
