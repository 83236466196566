.commentWrapper {
  max-width: 700px;
  display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-gap: 30px;
  align-items: center;
}

.commentWrapper .label {
  color: grey;
}

.openLink {
  margin-bottom: 20px;
}
