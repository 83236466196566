.itemLink {
  padding: 8px;
}

.itemLink:hover {
  background-color: white;
}

.itemTitle {
  display: flex;
  justify-content: space-between;
}
