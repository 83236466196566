.dayPickerCaptionInner {
  display: flex;
  align-items: flex-end;
  max-width: 170px !important;
}

.dayPickerMonth {
  width: 100px !important;
  padding: 5px !important;
}

.dayPickerYear {
  width: 70px !important;
  padding: 5px !important;
}

.DayPickerCaption {
  padding: 0 !important;
}
