.SortableItem {
  cursor: move;
  border: 1px dashed black;
  padding: 1rem;
  margin-bottom: 0.5rem;
  user-select: none;

  z-index: 9999;
}

.NonSortableItem {
  border: 1px solid black;
  padding: 1rem;
  margin-bottom: 0.5rem;
  user-select: none;
}

.width65 {
  width: 65%;
}

.width80 {
  width: 80%;
}

.width100 {
  width: 100%;
}

.SortableValue {
  display: inline-block;
}
