.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container > label {
  font-size: 16px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.container .field {
  width: 100%;
  border-radius: 0 !important;
  height: 48px;
  padding: 13px 7px !important;
  box-sizing: border-box;
  border: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 16px;
  line-height: 19px;
}

.container .hasErrors {
  outline-offset: -2px;
  outline: 2px solid red !important;
}

.todayBtn {
  color: #2185d0;
  cursor: pointer;
  font-size: 13px;

  margin-left: auto;
}
