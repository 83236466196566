.navigationWrapper {
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 3;
  box-shadow: 0 10px 11px 0 rgba(0, 0, 0, 0.09);
  height: 50px;

  padding: 5px 10px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
