.LinkButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #4183c4;
}

.LinkButton:hover,
.LinkButton:focus {
  text-decoration: underline;
}
