.createButtonContainer {
  height: 48px;
}

.createButton {
  height: 100%;
}

@media (max-width: 768px) {
  .createButtonContainer {
    margin: 10px 5px;
  }
}
