.footerRow {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.showAdvancedLabel {
  font-size: 16px;
  color: #2185d0;
  cursor: pointer;
}

.advancedLabelRow {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
}
