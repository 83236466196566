.Range {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
}

.Input input {
  min-width: unset !important;
  width: 100% !important;
}

.Cell {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.5rem;
}

.SaveButton {
  margin-left: 1rem;
}

.Warning {
  background-color: lightyellow;
}

.Error {
  background-color: lightcoral;
}

.EditComment {
  color: darkgoldenrod;
}
