.TableWrapper {
  position: relative;
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.TableFooter {
  position: sticky;
  bottom: 0;
}

.Bolded {
  font-weight: 700 !important;
}
